<template>
<div>
  <div class="relative overflow-hidden bg-no-repeat bg-cover" style="
    background-position: 50%;
    background-image: url('/facts_bg.jpg');
    height: 550px;
  ">

    <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
         style="background-color: rgba(0, 0, 0, 0.80)">
<span class="justify-center flex">
  <h3 class="text-3xl font-bold text-white mt-20" style="font-weight: bold; font-size: 35px;">USM Facts</h3>
  <div>

  </div>
  <br/>
</span>
      <div class="flex justify-center items-center mt-32 grid grid-cols-3 gap-4 container">
        <div class="text-center text-white px-6 md:px-12">
          <h3 class="text-3xl font-bold mb-8">Age</h3>
          <h1 class="text-5xl font-bold mt-0 mb-6">85+</h1>
        </div>
        <div class="text-center text-white px-6 md:px-12">
          <h3 class="text-3xl font-bold mb-8">Clubs</h3>
          <h1 class="text-5xl font-bold mt-0 mb-6">25</h1>
        </div>
        <div class="text-center text-white px-6 md:px-12">
          <h3 class="text-3xl font-bold mb-8">Members</h3>
          <h1 class="text-5xl font-bold mt-0 mb-6">16</h1>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "facts"
}
</script>

<style scoped>

</style>