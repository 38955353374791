<template>
  <div>
    <sharedHead title="Contact Us"/>
    <div class="container">
      <div class="grid grid-cols-4 gap-4 space-x-4 " style="margin-top: 100px;">
        <div class="col-span-2">
          <div class="border-b-2 border-yellow-500" style="">
            <h1 class="uppercase font-semibold mb-4 flex justify-center md:justify-start text-blue-900" style="font-size: 30px;">
              Reach Us At
            </h1>
          </div>

          <p class="flex md:justify-start mb-4 mt-12">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cubes"
                 class="w-4 mr-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/>
            </svg>
            Northern Caribbean University Manchester Rd.<br/>
            Mandeville, Manchester<br/>
            Jamaica, West Indies.
          </p>

          <p class="flex items-center justify-center md:justify-start mb-4 mt-12">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                 class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
              </path>
            </svg>
            usm@ncu.edu.jm
          </p>
          <p class="flex items-center justify-center md:justify-start mb-4">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                 class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
              </path>
            </svg>
            876-963-7247
          </p>
        </div>
        <div class="col-span-2">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.538003921505!2d-77.500287277281!3d18.015432263165536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8edb95897b011ce9%3A0xccc9afeef93177c1!2sNorthern%20Caribbean%20University!5e0!3m2!1sen!2sjm!4v1665005788077!5m2!1sen!2sjm" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sharedHead from "@/components/sharedHead";
export default {
  name: "ContactUs",
  components:{
    sharedHead
  }
}
</script>

<style scoped>

</style>