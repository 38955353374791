<template>
<div class="container">
  <div class="border-b-2 border-yellow-500" style="margin-right: 500px; margin-top: 80px">
    <h1 class="text-blue-900 mb-1" style="font-size: 38px; font-weight: bold">Topics On The Hill Episodes</h1>
  </div>
  <br/><br/>
  <iframe width="560" height="315" src="https://www.youtube.com/embed/X1fZXvPhYy4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
</template>

<script>
export default {
  name: "topicsOnTheHill"
}
</script>

<style scoped>

</style>