<template>
<div>
  <footer class="text-center lg:text-left bg-gray-900 text-white mt-12">
    <div class="flex justify-center items-center lg:justify-between p-6 border-b border-gray-300">
      <div class="mr-12 hidden lg:block">
        <span>Get connected with us on social networks:</span>
      </div>
      <div class="flex justify-center">
        <a href="https://www.facebook.com/ncuusm" target="_blank" class="mr-6 text-gray-300 hover:text-yellow-500 transition duration-150 ease-in-out" data-bs-toggle="tooltip" title="Facebook">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
               class="w-2.5" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 320 512">
            <path fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
            </path>
          </svg>
        </a>
        <a href="https://twitter.com/ncuusm" target="_blank" class="mr-6 text-gray-300 hover:text-yellow-500 transition duration-150 ease-in-out" data-bs-toggle="tooltip" title="Twitter">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
               class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
            </path>
          </svg>
        </a>

        <a href="https://www.instagram.com/ncuusm/" target="_blank" class="mr-6 text-gray-300 hover:text-yellow-500 transition duration-150 ease-in-out" data-bs-toggle="tooltip" title="Instagram">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram"
               class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
            </path>
          </svg>
        </a>
        <a href="https://www.youtube.com/channel/UC3UYSeh9m2GppdpKS4m-nAg/featured" target="_blank" class="mr-6 text-gray-300 hover:text-yellow-500 transition duration-150 ease-in-out" data-bs-toggle="tooltip" title="Youtube">
          <svg  aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube"
                class="w-5" role="img"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
          </svg>
        </a>

        <a href="https://www.tiktok.com/@ncuusm2022" target="_blank" class="mr-6 text-gray-300 hover:text-yellow-500 transition duration-150 ease-in-out" data-bs-toggle="tooltip" title="Tik Tok">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="tiktok"
               class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
            <path fill="currentColor" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
          </svg>
        </a>
      </div>
    </div>
    <div class="mx-6 py-10 text-center md:text-left">
      <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div class="">
          <h6 class="
            uppercase
            font-semibold
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
          ">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cubes"
                 class="w-4 mr-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/>
            </svg>
            Location
          </h6>
          <p>
            Northern Caribbean University Manchester Rd.<br/>
            Mandeville, Manchester<br/>
            Jamaica, West Indies.
          </p>
        </div>
        <div class="">
          <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
            Quick Links
          </h6>
          <p class="mb-4">
            <router-link to="/" class="text-gray-300">Home</router-link>
          </p>
          <p class="mb-4">
            <router-link to="/about" class="text-gray-300">About Us</router-link>
          </p>
          <p class="mb-4">
            <router-link to="/news" class="text-gray-300">News</router-link>
          </p>
          <p>
            <router-link to="/contactUs" class="text-gray-300">Contact</router-link>
          </p>
        </div>
        <div class="">
          <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
            Useful links
          </h6>
          <p class="mb-4">
            <a href="https://signin.ncu.edu.jm/Account/Login?ReturnUrl=%2FOAuth%2FAuthorize%3Fclient_id%3D10008%26redirect_uri%3Dhttps%3A%2F%2Faeorion.ncu.edu.jm%2Foauth2callback.aspx%26response_type%3Dcode" target="_blank" class="text-gray-300">Aeorion</a>
          </p>
          <p class="mb-4">
            <a href="https://www.ncu.edu.jm/" target="_blank" class="text-gray-300">NCU website</a>
          </p>
          <p class="mb-4">
            <a href="http://hwrc.ncu.edu.jm/" target="_blank" class="text-gray-300">Library</a>
          </p>
          <p>
            <a href="/USM_constitution.pdf" target="_blank" class="text-gray-300"> Our Constitution</a>
          </p>
        </div>
        <div class="">
          <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
            Contact Us
          </h6>

          <p class="flex items-center justify-center md:justify-start mb-4">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                 class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
              </path>
            </svg>
            usm@ncu.edu.jm
          </p>
          <p class="flex items-center justify-center md:justify-start mb-4">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                 class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
              </path>
            </svg>
            876-963-7247
          </p>

        </div>
      </div>
    </div>
    <div class="text-center p-6 bg-gray-300">
      <span class="text-black">&copy; 2022 Copyright.</span>
      <a class="text-black font-semibold" href="https://tailwind-elements.com/">All Rights Reserved.</a>
    </div>
  </footer>
</div>
</template>

<script>
export default {
  name: "foot"
}
</script>

<style scoped>

</style>