<template>
  <menubar/>
  <router-view/>
  <foot></foot>
</template>

<script>
import foot from "@/components/foot";
import Menubar from "@/components/menubar";

export default {
  components:{
    Menubar,
    foot
  }
}
</script>

<style>

</style>
