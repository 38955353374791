<template>
<div>
  <!-- Header -->
  <div class="relative overflow-hidden bg-no-repeat bg-cover" style="
    background-position: 50%;
    background-image: url('https://mdbcdn.b-cdn.net/img/new/slides/146.webp');
    height: 350px;
  ">
    <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
         style="background-color: rgba(0, 0, 0, 0.75)">
      <div class="flex justify-center items-center h-full">
        <div class="text-center text-white px-6 md:px-12">
          <center><img src="../assets/hilltop_no_bg.png" width="100"/></center>
          <h1 class="text-5xl font-bold mt-0 mb-6">{{category.name}} News</h1>
        </div>
      </div>
    </div>
  </div>
  <!-- Header End -->

  <div class="container" style="margin-top: 50px;" >
    <div v-for="(blog, index) in blogs" :key="index">
      <div v-if="category.id == blog.categories">
        <div class="flex flex-start items-center" >
          <h2 class="font-large leading-tight text-4xl mt-0 mb-2 text-gray-800" >{{ blog.title.rendered }}</h2>
        </div>
        <div class="ml-6 mb-6 pb-6">
          <a href="#!" class="text-blue-600 hover:text-blue-700 focus:text-blue-800 duration-300 transition ease-in-out text-sm">Published on {{getPostDate(blog.date)}}</a>
          <p class="text-gray-700 mt-2 mb-4" v-html="blog.content.rendered.substring(0, 600)">
          </p>
          <button type="button"  @click="pushToFull(blog.id)" class="float-right inline-block px-4 py-1.5 bg-blue-900 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Read more</button>
          <br/><br/>
          <hr/>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import axios from 'axios';
import moment from "moment";
export default {
  name: "NewsFilter",
  data(){
    return{
      id: this.$route.params.id,
      blogs:[],
      categories:{},
    }
  },
  computed: {
    category: function () {
      return this.categories
    },
    watch: {
      blogs: function (n, o){
        console.log(n)
        console.log(o)
      }
    },

  },
  created() {
    this.id = this.$route.params.id;
    try {
      axios.get(`https://usm.ncu.edu.jm/api/wp-json/wp/v2/categories/${this.$route.query.id}`)
          .then(res => {
            this.categories = res.data
            console.log(this.categories)
          });
    } catch (error) {
      return alert('An Error occurred, please try again')
    }

  },

  mounted() {
    axios.get("https://usm.ncu.edu.jm/api/wp-json/wp/v2/posts?per_page=100")
        .then(res => {
          this.blogs = res.data
          console.log(this.blogs)
        });
    axios.get(`https://usm.ncu.edu.jm/api/wp-json/wp/v2/categories/${this.$route.query.id}`)
        .then(res => {
          this.categories = res.data
          console.log(this.categories)
        });
  },
  methods:{
    pushToFull(id){
      this.$router.push({
        path: '/newsArticle',
        query: {id}
      })
    },
    getPostDate(date){
      return moment(date).format('lll')
    }
  },
}
</script>

<style scoped>

</style>