<template>
<div class="container mt-20">
  <section class="mb-20 text-gray-700 px-4">
    <div class="max-w-4xl mx-auto text-center mb-16">
      <h2 class="" style="font-size: 40px; font-weight: bold">
        Unite With Us,
        <br>
        <span class="text-blue-900 " style="font-size: 40px;">Together We Achieve.</span>
      </h2>
      <div class="content-wrap text-lg lg:text-xl lmb0">
        <p class="" style="font-family: sans-serif; font-size: 22px;">
          The United Student Movement Council (USM) of Northern Caribbean University
          is the governing student leadership body of the university. It serves as the
          representative voice for students, and as a platform for deliberation, planning and
          student-led change.
        </p>
      </div>
    </div>
    <div class="grid md:grid-cols-2 gap-12 text-center">
      <div class="mb-6 md:mb-0">
        <div class="flex justify-center mb-6">
          <img
              src="../../assets/topicsOnTheHill.png"
              class="rounded-lg shadow-lg "
              width="200px"
          />
        </div>
        <p class="text-xl my-4 ">
          Topics on the Hill (TOTH) is the official vidcast of the USM. It was created by the
          students, for the students of the Northern Caribbean University to enlighten them
          on topics and issues relevant to them. Topics on the Hill is aired once a month on
          YouTube at NCU TV Jamaica.
        </p>
      </div>
      <div class="mb-0">
        <div class="flex justify-center mb-6">
          <img
              src="../../assets/hilltop_logo.jpg"
              class="rounded-lg shadow-lg "
              width="250px"
          />
        </div>
        <p class="text-xl my-4 ">
          The Hilltop Trumpet is the official newspaper of the USM.
        </p>

      </div>

    </div>

    <div class="flex space-x-2 justify-center">
      <button type="button" class=" mt-10 inline-block px-6 py-2.5 bg-blue-900 text-white font-medium text-m leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
        <b><router-link to="/about">More About Us</router-link></b>
      </button>
    </div>
  </section>
</div>
</template>

<script>
export default {
  name: "about"
}
</script>

<style scoped>

</style>