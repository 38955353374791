<template>
<div>
  <div class="flex items-center justify-center mb-1 mt-3">
    <div class="inline-flex shadow-md hover:shadow-lg focus:shadow-lg" role="group" v-for="(cate, index) in categories" :key="index">
      <button @click="pushToFilter(cate.id)" style="margin-right: 2px;" type="button" class="inline-block px-6 py-2.5 bg-blue-900 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
        {{cate.name}}
      </button>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "newsCategoriesMenu",
  data(){
    return{
      id: this.$route.params.id,
      categories:[],
    }
  },
  watch: {
    categories: function (n, o){
      console.log(n)
      console.log(o)
    }
  },

  mounted() {
    axios.get("http://usm.ncu.edu.jm/api/wp-json/wp/v2/categories?per_page=100")
        .then(res => {
          this.categories = res.data
          console.log(this.categories)
        });
  },
  methods:{
    pushToFilter(id){
      this.$router.push({
        path: '/newsFilter',
        query: {id}
      })
    },
    getPostDate(date){
      return moment(date).format('lll')
    }
  },
}
</script>

<style scoped>

</style>