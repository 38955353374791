
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue';
import Menubar from "@/components/menubar.vue";
import Carousel from "@/components/carousel.vue";
import About from "@/components/home/about.vue";
import Stories from "@/components/home/stories.vue";
import Facts from "@/components/home/facts.vue";
import testimonials  from "@/components/home/testimonials.vue";
import carousel2 from "@/components/carousel2.vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    Facts,
    Stories,
    About,
    Carousel,
    carousel2,
    Menubar,
    HelloWorld,
    testimonials
  },
});
