<template>
<div>
  <!-- Header -->
  <div class="relative overflow-hidden bg-no-repeat bg-cover" style="
    background-position: 50%;
    background-image: url('https://mdbcdn.b-cdn.net/img/new/slides/146.webp');
    height: 350px;
  ">
    <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
         style="background-color: rgba(0, 0, 0, 0.75)">
      <div class="flex justify-center items-center h-full">
        <div class="text-center text-white px-6 md:px-12">
          <center><img src="../assets/hilltop_no_bg.png" width="100"/></center>
          <h1 class="text-5xl font-bold mt-0 mb-6">Hilltop Trumpet</h1>
          <h3 class="text-3xl font-bold mb-8">The Official Newspaper of the USM</h3>
        </div>
      </div>
    </div>
  </div>
  <!-- Header End -->

  <!--Categories -->
  <!--<newsCategoriesMenu/>-->

  <div class="container" style="margin-top: 50px;">
    <!--Search Bar-->
    <div class="float-right">
      <div class="mb-10 xl:w-96">
        <div class="input-group relative flex flex-wrap items-stretch w-full mb-4">
          <input type="search" v-model="searchQuery" class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
          <button v-on:click="filteredList && !open"  class="btn inline-block px-6 py-2.5 bg-blue-900 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-900  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-900 active:shadow-lg transition duration-150 ease-in-out flex items-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
            </svg>
          </button>
          <!--<ul class=" dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none " aria-labelledby="dropdownMenuButton1d" >
            <li>

                <div v-for="(queryResult, index) in queryResults" :key="index" >
                  <a class="dropdown-item text-sm py-2 px-2 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100" href="#">
                  <div class="flex flex-start items-center">
                    <h2 class="leading-tight  mt-0 mb-2 text-gray-800" >{{ queryResult.title }}</h2>
                  </div>
                  <div class="ml-6 mb-6 pb-6">
                    <button type="button"  @click="pushToFull(queryResult.id)" class="float-right inline-block px-4 py-1.5 bg-blue-900 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">view</button>

                  </div>
                    <hr/>
                  </a>
                </div>


            </li>

          </ul>-->
          <ul v-show="open" class="  min-w-max relative hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1  m-0 bg-clip-padding border-none ">
            <li>
              <div v-for="(queryResult, index) in queryResults" :key="index" >
                <a class="dropdown-item text-sm py-2 px-2 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100" href="#">
                  <div class="flex flex-start items-center">
                    <h2 class="leading-tight  mt-0 mb-2 text-gray-800" >{{ queryResult.title }}</h2>
                  </div>
                  <div class="ml-6 mb-6 pb-6">
                    <button type="button"  @click="pushToFull(queryResult.id)"  class="float-right inline-block px-4 py-1.5 bg-blue-900 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">view</button>

                  </div>
                  <hr/>
                </a>
              </div>
            </li>

          </ul>
        </div>
        <!--Dropdown-->
        <div class="collapse" id="collapseExample">
          <div class="block p-6 rounded-lg shadow-lg bg-white">
            <div v-for="(queryResult, index) in queryResults" :key="index" >
              <div class="flex flex-start items-center">
                <h2 class="leading-tight  mt-0 mb-2 text-gray-800" >{{ queryResult.title }}</h2>
              </div>
              <div class="ml-6 mb-6 pb-6">
                <button type="button"  @click="pushToFull(queryResult.id)" class="float-right inline-block px-4 py-1.5 bg-blue-900 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Read more</button>
                <br/><br/>
                <hr/>
              </div>
            </div>
          </div>
        </div>

        <!--
        <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
             id="exampleModalScrollable" tabindex="-1" aria-labelledby="exampleModalScrollableLabel" aria-hidden="true" style="margin-right: 200px;">
          <div class="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
            <div
                class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div
                  class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
                  Search Results
                </h5>
                <button type="button"
                        class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                        data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body relative p-4">

              </div>
              <div
                  class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                <button type="button"
                        class="inline-block px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                        data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>



    <article-list v-if="loading"/>



        <div class="container" style="margin-top: 50px;" >


        <br/><br/><br/>

        <div v-for="(blog, index) in blogs" :key="index">
          <div class="flex flex-start items-center">
            <h2 class="font-large leading-tight text-4xl mt-0 mb-2 text-gray-800" >{{ blog.title.rendered }}</h2>
          </div>
          <div class="ml-6 mb-6 pb-6">
            <a href="#!" class="text-blue-600 hover:text-blue-700 focus:text-blue-800 duration-300 transition ease-in-out text-sm">Published on {{getPostDate(blog.date)}}</a>
            <div class="text-gray-700 mt-2 mb-4" v-html="blog?.content.rendered">
            </div>
            <button type="button"  @click="pushToFull(blog.id)" class="float-right inline-block px-4 py-1.5 bg-blue-900 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Read more</button>
            <br/><br/>
            <hr/>
          </div>

        </div>


      </div>



</div>
</template>

<script >
import axios from 'axios';
import moment from "moment";
import newsCategoriesMenu from '../components/newsCategoriesMenu.vue';
import ArticleList from "@/components/newsArticle/articleList";

import {ref} from "vue";

export default {
  name: "NewsArticles",
  components:{
    ArticleList,
    newsCategoriesMenu
  },


  data(){
    return{
      id: this.$route.params.id,
       blogs:[],
      queryResults:[],
      searchQuery: "",
      loading: true,
      open: false,
    }
  },



  computed: {
    filteredList() {
      axios.get(`https://usm.ncu.edu.jm/api/wp-json/wp/v2/search?search=${this.searchQuery}`).
      then( res => {
        this.queryResults = res.data
        console.log(this.queryResults)
      });
      return this.queryResults
    },
  },
  watch: {
    blogs: function (n, o){
      console.log(n)
      console.log(o)
    },
    queryResults: function (n, o){
      console.log(n)
      console.log(o)
    }
  },
   mounted() {
     setTimeout(() => {
       axios.get('https://usm.ncu.edu.jm/api/wp-json/wp/v2/posts?per_page=100')
           .then(res => {
             this.blogs = res.data;
             this.loading = false;
             console.log("Blog API"+this.data)
           });

     }, 2000);
  },

  methods:{

    pushToFull(id){
      this.$router.push({
        path: '/newsArticle',
        query: {id},
      })
    },
    getPostDate(date){
      return moment(date).format('lll')
    }
  },


}
</script>

<style scoped>
.item {
  width: 350px;
  margin: 0 auto 10px auto;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.post {
  background-color: rgb(97, 62, 252);
  cursor: pointer;
}
</style>