<template>
  <div>
    <sharedHead title="Meet the USM"/>
    <div class="container" style="margin-top: 100px;">
      <section class="mb-20 text-gray-700">
        <div class="text-center md:max-w-xl lg:max-w-3xl mx-auto">
          <h3 class="text-3xl font-bold mb-6 text-gray-800">Our Executive Members</h3>
          <p class="mb-6 pb-2 md:mb-12 md:pb-0">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet numquam
            iure provident voluptate esse quasi, veritatis totam voluptas nostrum quisquam eum
            porro a pariatur veniam.
          </p>
        </div>

        <div class="grid md:grid-cols-3 gap-6 text-center">
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms. Leandra Wilmot</h4>
                <h4 class="text-2xs font-semibold mb-2">President</h4>
              </div>

            </div>
          </div>
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-yellow-500" ></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms. Trillian Pinnock</h4>
                <h4 class="text-2xs font-semibold mb-2">V.P. of Academic Administration</h4>

              </div>
            </div>
          </div>
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Mr. Romario Griffiths</h4>
                <h4 class="text-2xs font-semibold mb-2">V.P. of Finance and Planning</h4>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section class="mb-20 text-gray-700">
        <div class="grid md:grid-cols-3 gap-6 text-center">
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms. Danae Ellis</h4>
                <h4 class="text-2xs font-semibold mb-2">V.P. of Student Services and Social Affairs</h4>

              </div>
            </div>
          </div>
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-yellow-500" ></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms. Rojaune Miller</h4>
                <h4 class="text-2xs font-semibold mb-2"> V.P. of Spiritual Affairs</h4>
              </div>
            </div>
          </div>
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms. Dorrethea Grant</h4>
                <h4 class="text-2xs font-semibold mb-2">Administrative Assistant</h4>

              </div>

            </div>
          </div>
        </div>
      </section>
      <section class="mb-20 text-gray-700">
        <div class="grid md:grid-cols-3 gap-6 text-center">
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms. Toniann Barrett</h4>
                <h4 class="text-2xs font-semibold mb-2">Associate Administrative Assistant</h4>
              </div>

            </div>
          </div>
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-yellow-500" ></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms. Khalia Johnson</h4>
                <h4 class="text-2xs font-semibold mb-2">Director of Elections, Policy and Constitutional Affairs</h4>

              </div>
            </div>
          </div>
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Mr. Khamal Campbell</h4>
                <h4 class="text-2xs font-semibold mb-2">Director of Projects and Special Initiatives</h4>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section class="mb-20 text-gray-700">
        <div class="grid md:grid-cols-3 gap-6 text-center">
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms Tamara Wisdom</h4>
                <h4 class="text-2xs font-semibold mb-2">Assc. Director of Projects and Special Initiatives </h4>
              </div>
            </div>
          </div>
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-yellow-500" ></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Mr. Shanroy Foster</h4>
                <h4 class="text-2xs font-semibold mb-2">Treasurer</h4>
              </div>
            </div>
          </div>
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Mr. James Barrett</h4>
                <h4 class="text-2xs font-semibold mb-2">Public Relations Officer</h4>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section class="mb-20 text-gray-700">
        <div class="grid md:grid-cols-3 gap-6 text-center">
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms. Nickallaye Gunn</h4>
                <h4 class="text-2xs font-semibold mb-2">Associate Public Relations Officer</h4>
              </div>
            </div>
          </div>
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-yellow-500" ></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Mr. Donte Whittingham</h4>
                <h4 class="text-2xs font-semibold mb-2">Male Sports Director</h4>
              </div>
            </div>
          </div>
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms. Shevreina Crooks</h4>
                <h4 class="text-2xs font-semibold mb-2">Female Sports Director</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mb-20 text-gray-700">
        <div class="grid md:grid-cols-3 gap-6 text-center">
          <div>
            <div class="block rounded-lg shadow-lg bg-white">
              <div class="overflow-hidden rounded-t-lg h-28 bg-blue-900"></div>
              <div class="w-24 -mt-12 overflow-hidden border border-2 border-white rounded-full mx-auto bg-white">
                <img src="../assets/avatar.png" />
              </div>
              <div class="p-6">
                <h4 class="text-2xl font-semibold mb-2">Ms. Chantae McNeil</h4>
                <h4 class="text-2xs font-semibold mb-2">Director of Public Relations</h4>
              </div>

            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
</template>

<script>
import sharedHead from "@/components/sharedHead";
export default {
  name: "MeetTheUSM",
  components:{
    sharedHead
  }
}

</script>

<style scoped>

</style>