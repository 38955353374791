<template>
<div>
  <calendar/>
  <br/>
  <hilltop/>
  <br/><br/>
  <TopicsOnTheHill/>

</div>
</template>

<script>
import Calendar from "@/components/events/calendar";
import TopicsOnTheHill from "@/components/events/topicsOnTheHill";
import Hilltop from "@/components/events/hilltop";
export default {
  name: "Events",
  components: {Hilltop, TopicsOnTheHill, Calendar}
}
</script>

<style scoped>

</style>