<template>
<div class="container ">
  <div class="border-b-2 border-yellow-500" style="margin-right: 700px; margin-top: 80px;">
    <h1 class="text-blue-900 mb-1" style="font-size: 38px; font-weight: bold">Featured Stories</h1>
  </div>
  <br/><br/>
<div  class="grid md:grid-cols-2 gap-4 text-center" id="news">
  <div class="">
    <img src="../../assets/sports.jpg" width="600px;">
    <a href="">
      <h1 class="text-blue-900 " style="font-size: 30px; font-weight: bold">Iste Quod Quibusdam Maxime Recusandae</h1>
    </a>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis, libero iste quod quibusdam maxime
      recusandae odit eveniet, aspernatur culpa provident error molestiae vitae corporis in vero est! Beatae,
      ipsum voluptatibus.
    </p>
  </div>
  <div class="">
    <div class="flex justify-center">
      <ul class="bg-white rounded-lg w-96 text-gray-900">
        <li class="px-6 text-blue-900  py-2 border-b border-gray-200 w-full rounded-t-lg" style="font-size: 20px; ">
          <a href="">
            libero iste quod quibusdam maxime recusandae odit eveniet
          </a>
        </li>
        <li class="px-6 text-blue-900  py-2 border-b border-gray-200 w-full" style="font-size: 20px;">
          <a href="">
            libero iste quod quibusdam maxime recusandae odit eveniet
          </a>
        </li>
        <li class="px-6 text-blue-900  py-2 border-b border-gray-200 w-full" style="font-size: 20px; ">
          <a href="">
            libero iste quod quibusdam maxime recusandae odit eveniet
          </a>
        </li>
        <li class="px-6 text-blue-900  py-2 border-b border-gray-200 w-full" style="font-size: 20px; ">
          <a href="">
            libero iste quod quibusdam maxime recusandae odit eveniet
          </a>
        </li>
        <li class="px-6 text-blue-900  py-2 w-full rounded-b-lg" style="font-size: 20px;">
          <a href="">
            libero iste quod quibusdam maxime recusandae odit eveniet
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
  name: "hilltop"
}
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  #news{
    margin-right: 50px;
  }
}
</style>