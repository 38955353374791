<template>
  <div class="container">
    <section class="mb-20 text-gray-700">
      <div class="text-center md:max-w-xl lg:max-w-3xl mx-auto">
        <h3 class="text-3xl font-bold mb-6 text-blue-900 mt-32" style="font-weight: bold; font-size: 45px;">Student Testimonials</h3>
      </div><br/><br/>

      <div class="grid md:grid-cols-3 gap-6 lg:gap-12 text-center">
        <div class="mb-12 md:mb-0">
          <div class="flex justify-center mb-6">
            <img
                src="../../assets/grizzle_testimony.jpg"
                class=" shadow-lg w-22 rounded-lg"
            />
          </div>
          <h5 class="text-xl font-semibold mb-4 text-blue-900">Truston Grizzle</h5>

          <p class="mb-4">
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="quote-left"
                class="w-6 pr-2 inline-block"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
              <path
                  fill="currentColor"
                  d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
              ></path>
            </svg>
            The United Student Movement (USM) has indeed helped me as a student at NCU by, among other things: Giving me the opportunity to hone my
            communication and public speaking skills by virtue of me hosting several programs for the movement.
          </p>

        </div>
        <div class="mb-12 md:mb-0">
          <div class="flex justify-center mb-6">
            <img
                src="../../assets/ginelle_testimony.jpg"
                class=" shadow-lg w-22 rounded-lg "
            />
          </div>
          <h5 class="text-xl font-semibold mb-4 text-blue-900">Ginelle Thompson</h5>

          <p class="mb-4">
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="quote-left"
                class="w-6 pr-2 inline-block"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
              <path
                  fill="currentColor"
                  d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
              ></path></svg>Prior to joining USM, I used to be very reserved. However, after becoming a member of their
            team and engaging in collaborative work, I have improved my social abilities, honed my leadership traits,
            and gained a deeper understanding of my own strengths and weaknesses.
          </p>

        </div>
        <div class="mb-0">
          <div class="flex justify-center mb-6">
            <img
                src="../../assets/marvin_testimony.jpg"
                class="shadow-lg w-22 rounded-lg "
            />
          </div>
          <h5 class="text-xl font-semibold mb-4 text-blue-900">Marvin Pryce</h5>

          <p class="mb-4">
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="quote-left"
                class="w-6 pr-2 inline-block"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
            >
              <path
                  fill="currentColor"
                  d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
              ></path></svg>USM has helped me with my leadership skills in various aspects. Serving as the Director of
            EPCA it has taught me about good time management and accountability. Teamwork and also tolerance.
          </p>

        </div>
      </div>
      <div class="flex space-x-2 justify-center">
        <!--<button type="button" class=" mt-10 inline-block px-6 py-2.5 bg-blue-900 text-white font-medium text-m leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
          <b><router-link to="/news">Read More</router-link></b>
        </button>-->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "testimonials"
}
</script>

<style scoped>

</style>