<template>
  <div>
    <div class="relative overflow-hidden bg-no-repeat bg-cover " id="carouselBG" style="
    background-position: 50%;
    background-image: url('/ncu_bg.jpg');
    height: 700px;
  ">

      <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
           style="background-color: rgba(0, 0, 0, 0.80)">
        <div class="flex justify-center items-center h-full">
          <div class="text-center text-white px-6 md:px-12">
            <center><img src="../assets/usm_logo.png" width="40"/></center>
            <br/>
            <h1 class="text-5xl font-bold mt-0 mb-6">United Student Movement</h1>
          </div>
        </div>
        <!--<div id="carouselExampleCaptions1" class="carousel slide relative container" data-bs-ride="carousel">
          <div class="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4 lg:mt-32">
            <button
                type="button"
                data-bs-target="#carouselExampleCaptions1"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleCaptions1"
                data-bs-slide-to="1"
                aria-label="Slide 2"
            ></button>
            <button
                type="button"
                data-bs-target="#carouselExampleCaptions1"
                data-bs-slide-to="2"
                aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner relative w-full overflow-hidden">
            <div class="carousel-item active relative float-left w-full">
              <img
                  src="../assets/banner2.jpg"
                  class="block w-full h-[450px]"
                  alt="..."
              />
              <div class="carousel-caption hidden md:block absolute text-center">
                <h5 class="text-xl">First slide label</h5>
                <p>Some representative placeholder content for the first slide.</p>
              </div>
            </div>
            <div class="carousel-item relative float-left w-full">
              <img
                  src="https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg"
                  class="block w-full "
                  alt="..."
              />
              <div class="carousel-caption hidden md:block absolute text-center">

              </div>
            </div>
            <div class="carousel-item relative float-left w-full">
              <img
                  src="https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg"
                  class="block w-full"
                  alt="..."
              />
              <div class="carousel-caption hidden md:block absolute text-center">


              </div>
            </div>
          </div>
          <button
              class="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
              type="button"
              data-bs-target="#carouselExampleCaptions1"
              data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
              class="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
              type="button"
              data-bs-target="#carouselExampleCaptions1"
              data-bs-slide="next"
          >
            <span class="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>-->
      </div>
    </div> 
  </div>
</template>

<script >
export default {
  name: "carousel",
  methods: {

  }
}
</script>

<style scoped>
 @media only screen and (max-width: 1440px) {
  #carouselExampleCaptions1{
    margin-top: 70px;
  }
}
 @media only screen and (max-width: 768px) {
   #carouselExampleCaptions1{
     width: 700px;
   }
 }
 @media only screen and (max-width: 425px) {
   #carouselExampleCaptions1{
     width: 400px;
   }
   #carouselBG{
     height: 300px;
   }
 }
</style>