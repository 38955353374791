<template>
<div>
  <div class="relative overflow-hidden bg-no-repeat bg-cover" style="
    background-position: 50%;
    background-image: url('/usm_secondary_head.jpg');
    height: 250px;
  ">
    <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
         style="background-color: rgba(0, 0, 0, 0.75)">
      <div class="flex justify-center items-center h-full">
        <div class="text-center text-white px-6 md:px-12">
          <center><img src="../assets/usm_logo.png" width="40"/></center>
          <br/>
          <h1 class="text-5xl font-bold mt-0 mb-6">{{title}}</h1>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "sharedHead",
  props:{
    title: String
  }
}
</script>

<style scoped>

</style>