<template>

<div >
  <div class="relative overflow-hidden bg-no-repeat bg-cover" style="
    background-position: 50%;
    background-image: url('https://mdbcdn.b-cdn.net/img/new/slides/146.webp');
    height: 300px;
  ">
    <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
         style="background-color: rgba(0, 0, 0, 0.75)">
      <div class="flex justify-center items-center h-full">
        <div class="text-center text-white px-6 md:px-12">
          <center><img src="../assets/hilltop_no_bg.png" width="100"/></center>
          <h1 class="text-5xl font-bold mt-0 mb-6">USM Newspaper</h1>
          <h3 class="text-3xl font-bold mb-8">News Article</h3>
        </div>
      </div>
    </div>
  </div>


  <SingleArticleSkeleton v-if="loading"/>

  <div class="container" style="margin-top: 60px;">
    <div >
      <div class="flex flex-start items-center">
        <h2 class="font-large leading-tight text-4xl mt-0 mb-2 text-gray-800" >{{ blog.title.rendered }}</h2>
      </div>
      <div class="ml-6 mb-6 pb-6">
        <a href="#" class="text-blue-600 hover:text-blue-700 focus:text-blue-800 duration-300 transition ease-in-out text-sm">Published on {{getPostDate(blog.date)}}</a>
        <p class="text-gray-700 mt-2 mb-4" v-html="blog.content.rendered">
        </p>
<!--        <router-link to="/news">-->
          <button type="button"  @click="pushToPrev"  class="float-right inline-block px-4 py-1.5 bg-blue-900 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
            view more
          </button>
<!--        </router-link>-->
      </div>

    </div>

  </div>
</div>
</template>

<script>
import  axios from "axios";
import moment from "moment";
import SingleArticleSkeleton from "@/components/newsArticle/singleArticleSkeleton.vue";

export default {
  name: "NewsArticleDetail",
  components: {
    SingleArticleSkeleton
  },
  data(){
    return {
      id: this.$route.query.id,
      blogs: {},
      loading: true,
    }
  },
  computed: {
    blog: function () {
        return this.blogs
    },
  },
  created() {
      this.id = this.$route.params.id;
        setTimeout(() => {
        axios.get(`https://usm.ncu.edu.jm/api/wp-json/wp/v2/posts/${this.$route.query.id}`)
            .then(res => {
              this.blogs = res.data;
              this.loading = false;
              console.log(this.blogs)
            });
        }, 2000);

  },

  mounted() {
    setTimeout(() => {
      axios.get(`https://usm.ncu.edu.jm/api/wp-json/wp/v2/posts/${this.$route.query.id}`)
          .then(res => {
            this.blogs = res.data;
            this.loading = false;
            console.log(this.blogs)
          });

    }, 2000);

  },

  methods:{
    pushToPrev(){
      window.location.href = "/news"
      this.$router.replace({
        path: '/news'
      });

    },
    getPostDate(date){
      return moment(date).format('lll')
    }
  }

}
</script>

<style scoped>

</style>