<template>
  <div>

    <div class="container">
      <div class="animate-pulse mb-12 mt-12 flex space-x-4">

        <div class="flex-1 space-y-6 py-1">

          <div class="grid grid-cols-5 gap-4">
            <div class="h-5 bg-slate-300 rounded col-span-3"></div>
            <div class="h-2 white rounded col-span-2"></div>
          </div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-slate-300 rounded col-span-1"></div>
              <div class="h-2 white rounded col-span-2"></div>
            </div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div><br/>

            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div><br/>

            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div><br/>

            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div><br/>

            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div>
            <div class="h-2 bg-slate-300 rounded"></div><br/>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "singleArticleSkeleton"
}
</script>

<style scoped>

</style>