<template>
<div class="container">

  <div class="border-b-2 border-yellow-500 mt-20" style="margin-right: 700px">
    <h1 class="text-blue-900 mb-1" style="font-size: 38px; font-weight: bold">Upcoming Events</h1>
  </div>

 <div class="overflow-y-scroll mt-12 mb-12 " style="height: 500px;">
   <div class="flex justify-center">
     <div class="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg">
       <div class="bg-black text-white py-12 px-12 rounded-lg text-center" style="font-weight: bold; font-size: 25px">
           Feb<br/>1
       </div>
       <div class="p-6 flex flex-col justify-start">
         <h5 class="text-gray-900 text-xl font-medium mb-2">USM Alumni Homecoming</h5>
         <p class="text-gray-700 text-base mb-4">
           This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
         </p>
         <p class="text-gray-600 text-xs"></p>
       </div>
     </div>
   </div>
   <br/>
   <div class="flex justify-center">
     <div class="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg">
       <div class="bg-black text-white py-12 px-12 rounded-lg text-center" style="font-weight: bold; font-size: 25px">
         Feb<br/>20
       </div>
       <div class="p-6 flex flex-col justify-start">
         <h5 class="text-gray-900 text-xl font-medium mb-2">Main Campus Sports Day</h5>
         <p class="text-gray-700 text-base mb-4">
           This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
         </p>
         <p class="text-gray-600 text-xs"></p>
       </div>
     </div>
   </div>
   <br/>
   <div class="flex justify-center">
     <div class="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg">
       <div class="bg-black text-white py-12 px-12 rounded-lg text-center" style="font-weight: bold; font-size: 25px">
         Apr<br/>7
       </div>
       <div class="p-6 flex flex-col justify-start">
         <h5 class="text-gray-900 text-xl font-medium mb-2">Handing Over Ceremony</h5>
         <p class="text-gray-700 text-base mb-4">
           This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
         </p>
         <p class="text-gray-600 text-xs"></p>
       </div>
     </div>
   </div>
<br/>
   <div class="flex justify-center">
     <div class="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg">
       <div class="bg-black text-white py-12 px-12 rounded-lg text-center" style="font-weight: bold; font-size: 25px">
         Jun<br/>20
       </div>
       <div class="p-6 flex flex-col justify-start">
         <h5 class="text-gray-900 text-xl font-medium mb-2">Main Campus Cleanup Day</h5>
         <p class="text-gray-700 text-base mb-4">
           This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
         </p>
         <p class="text-gray-600 text-xs"></p>
       </div>
     </div>
   </div>
 </div>
</div>
</template>

<script>
export default {
  name: "calendar",

  data(){

  }
}
</script>

<style scoped>

</style>