<template>
<div>
  <nav
      class="relative navi  w-full flex flex-wrap items-center justify-between py-3 text-white shadow-lg navbar navbar-expand-lg navbar-light bg-gray-900 "
      style=" background-color: #1c2855;"
  >
    <div class="container-fluid w-full flex flex-wrap items-center justify-between px-6">
      <button
          class="navbar-toggler text-white border-0 hover:shadow-none hover:no-underline py-2 px-2.5 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent1"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
      >
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="bars"
            class="w-6"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
        >
          <path
              fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
          ></path>
        </svg>
      </button>
      <div class="collapse navbar-collapse flex-grow items-center" id="navbarSupportedContent1">
        <img src="../assets/usm_logo.png" width="40" class="ml-20">
        <!-- Left links -->
        <div class=" justify-center">

          <ul class="navbar-nav flex flex-col pl-0 list-style-none mr-auto ml-20">
            <li class="nav-item p-2">
              <router-link to="/" class="nav-link text-white font-bold" href="#" style="font-size: 18px">Home</router-link>
            </li>
            <li class="nav-item p-2">

              <div class="dropdown relative">
                <a
                    class="nav-link text-white font-bold hover:opacity-80 focus:opacity-80 p-0 "
                    id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                    href="#"
                    style="font-size: 18px">About Us</a>

                <ul class="dropdown-menu min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none left-auto right-0 " aria-labelledby="dropdownMenuButton1">
                  <li>
                    <router-link to="/history" class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 " href="#">
                      Our History
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/meetUSM" class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100 " href="#">
                      Meet the USM
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/about" class="
          dropdown-item
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-gray-700
          hover:bg-gray-100
        " href="#">Roles and Responsibilities</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item p-2">

              <router-link to="/news"
                           class="nav-link text-white font-bold hover:opacity-80 focus:opacity-80 p-0"
                           href="#"
                           style="font-size: 18px"
              >News</router-link
              >
            </li>
            <li class="nav-item p-2">
              <router-link to="/contactUs"
                           class="nav-link text-white font-bold hover:opacity-80 focus:opacity-80 p-0"
                           href="#" style="font-size: 18px"
              >
                Contact Us
              </router-link>
            </li>
          </ul>

        </div>
        <!-- Left links -->
      </div>
      <!-- Collapsible wrapper -->

      <!-- Right elements -->
      <div class="flex items-center relative" style="margin-right: 100px;">

      </div>
    </div>
    <!-- Right elements -->

  </nav>
</div>
</template>

<script>
export default {
  name: "menubar"
}
</script>

<style scoped>

</style>