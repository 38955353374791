<template>
<div>
  <sharedHead title="History"/>
  <div class="container" id="details" style="margin-top: 100px">
    <div class="border-b-2 border-yellow-500" style="">
      <h1 class="text-blue-900 mb-2" style="font-size: 40px; font-weight: bold">The History of the United Students Movement</h1>
    </div>
    <br/><br/>
    <ol class="border-l border-gray-400" >
      <li>
        <div class="flex flex-start items-center pt-3">
          <div class="bg-gray-400 w-2 h-2 rounded-full -ml-1 mr-3"></div>
          <p class="text-gray-500 text-sm">1935</p>
        </div>
        <div class="mt-0.5 ml-4 mb-6">
          <h4 class="text-blue-900 font-semibold  text-xl mb-1.5">USM is Established</h4>
          <img class="float-right rounded-lg" src="../assets/usm_history1.jpg" width="180px;">
          <p class=" mb-3 ">
            The United Student Movement (USM) began in 1935 with a handful of students
            under the influence of Bender L. Archibald. The objectives of the first constitution
            were to aid in fostering a good school spirit, promote physical health education
            and to aid in the development of the student body as a whole.
            As the years went by, the objectives of the United Student Movement were
            developed to encourage democratic leadership, provide a well-organized channel
            between students and the administration, in collaboration with the objectives and
            policies of the school.
          </p>
          <p>
            Students such as Wesley, Fletchers, Harriots and William put new life
            enthusiasm and direction in the organization. It quickly became a well-defined
            association that attracted faculty and staff’s interest and support. USM began to
            develop various projects that the student body accepted as a means of working
            together to accomplish something as a corporate body.
          </p>
        </div>
      </li>
      <li>
        <div class="flex flex-start items-center pt-2">
          <div class="bg-gray-400 w-2 h-2 rounded-full -ml-1 mr-3"></div>
          <p class="text-gray-500 text-sm">1940s - 1960s</p>
        </div>
        <div class="mt-0.5 ml-4 mb-6">
          <h4 class="text-blue-900 font-semibold text-xl mb-1.5">Expansion of the Movement</h4>
          <p class="mb-3">
            The fame of the movement eventually grew, left the Jamaican shores and
            attracted foreign students from several other Caribbean territories, many of
            whom had a role in the administration of USM. The United Student Movement in
            the 1940s saw the leadership of Dr. Lloyd Dayes and Dr. Alvin Mclean. The 60’s
            saw leadership of Dr. Trevor Gardener and Dr. Newton Hoilette.
          </p>
        </div>
      </li>
      <li>
        <div class="flex flex-start items-center pt-2">
          <div class="bg-gray-400 w-2 h-2 rounded-full -ml-1 mr-3"></div>
          <p class="text-gray-500 text-sm">1970s -1980s</p>
        </div>
        <div class="mt-0.5 ml-4 pb-5">
          <h4 class="text-blue-900 font-semibold text-xl mb-1.5">Suspension of the Movement</h4>
          <p class="mb-3">
            The early 70’s saw the suspension of USM as an organized body for about two
            years. After a revision of the constitution and an understanding by the students
            as to the proper running of the Movement, it was reorganized and reintroduced to
            the students. Various activities, including the student of the year competition
            were also introduced.
          </p>
          <p>
            Up until 1977, faculty members were granted associate membership with the
            movement. By April 1981, an amendment to the constitution stated that no
            provision was to be made for the associate membership of the faculty and staff of
            the USM.
          </p>
        </div>
      </li>
      <li>
        <div class="flex flex-start items-center pt-2">
          <div class="bg-gray-400 w-2 h-2 rounded-full -ml-1 mr-3"></div>
          <p class="text-gray-500 text-sm">Present</p>
        </div>
        <div class="mt-0.5 ml-4 pb-5">
          <h4 class="text-blue-900 font-semibold text-xl mb-1.5">USM in the 21st Century</h4>
          <p class="mb-3">
            The United Student Movement continues to advocate, represent and inspire
            change among the students of the Northern Caribbean University.
            <b> “Unite we must, together we achieve.”</b>
          </p>

        </div>
      </li>
    </ol>
    <img src="../assets/usm_history2.jpg" class="shadow-lg rounded-lg  mt-12 " width="550px">
  </div>
</div>
</template>

<script>
import sharedHead from "@/components/sharedHead";
export default {
  name: "History",
  components:{
    sharedHead
  }
}
</script>

<style scoped>
@media only screen and (max-width: 425px) {
 #details{
   margin-left: 5px;
 }
}
</style>