import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import History from '../views/History.vue'
import MeetUSM from '../views/MeetTheUSM.vue'
import AboutView from "@/views/AboutView.vue";
import Events from '../views/Events.vue';
import ContactUs from '../views/ContactUs.vue'
import NewsArticles from '../views/NewsArticles.vue';
import NewsArticleDetail from '../views/NewsArticleDetail.vue';
import NewsFilter from "@/views/NewsFilter.vue";

let routes: Array<RouteRecordRaw>;
routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/newsArticle',
    name: 'newsArticle',
    component: NewsArticleDetail
  },
  {
    path: '/newsFilter',
    name: 'newsFilter',
    component: NewsFilter
  },
  {
    path: '/news',
    name: 'news',
    component: NewsArticles
  },
  {
    path: '/history',
    name: 'history',
    component: History
  },
  {
    path: '/meetUSM',
    name: 'meetUSM',
    component: MeetUSM
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/events',
    name: 'events',
    component: Events
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return {top: 0}
  },
});

export default router
